<template>
  <div class="beginnerSGuide page">
    <Headers title="新手指引"></Headers>
    <div class="scroll">
      <div class="banner">
        <img src="../../../static/img/xinshou.png" alt="">
      </div>
      <div class="list">
        <div class="item" v-for="(item,index) in list" :key="index" @click="toDetail(item.id)">
          <p>{{index+1}}、{{item.title}}</p>
          <van-icon name="arrow" color="rgb(213, 213, 213)" size="20"/>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import Headers from "@/components/headers.vue";
import {xinshou} from "@/axios/api";
export default {
  name: "beginnerSGuide",
  components: {
    Headers
  },
  mounted() {
    this.getList()
  },
  data() {
    return {
      list: []
    }
  },
  methods: {
    getList(){
      $api.xinshou({
        token: this.$store.getters["getToken"]
      }).then(res => {
        res.data.length>0 && (this.list = res.data)
      }).catch(err => {

      })
    },
    toDetail(id){
      this.$router.push({
        path: '/newdetails',
        query: {
          id: id
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
.beginnerSGuide {
  background: #fff;
  .banner {
    margin: 20px 15px 0;
    img {
      width: 100%;
    }
  }
  .list {
    padding: 10px 15px;
    .item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 54px;
      font-size: 16px;
    }
  }
}
</style>